.App {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.table {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .rdrDefinedRangesWrapper {
  display: none !important;
} */
.rdrCalendarWrapper {
  font-size: 14px !important;
  color: #1e1d1f !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.rdrNextPrevButton {
  display: block !important;
  width: 42px !important;
  height: 42px !important;
  margin: 0 0.833em !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 23px !important;
  background: #eff2f7 !important;
}
.rdrNextButton i {
  margin: 0 0 0 16px !important;
  border-width: 4px 4px 4px 6px !important;
  border-color: transparent transparent transparent rgb(52, 73, 94) !important;
  transform: translate(3px, 0px) !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgba(255, 255, 255) !important;
}
.rdrDayToday .rdrDayNumber span:after {
  content: "" !important;
  position: absolute !important;
  bottom: 4px !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  width: 18px !important;
  height: 2px !important;
  border-radius: 2px !important;
  background: #8217ff !important;
}
.rdrDateDisplayItem {
  display: contents !important;
}
.rdrDateDisplay {
  margin: 0 !important;
}
.rdrMonthsVertical {
  align-items: center !important;
}
@media (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column !important;
  }
}
/* @media (max-width: 600px) {

.rdrDateRangePickerWrapper{
  max-width: 300px !important;
}
} */

.MuiColorInput-Button {
  margin-right: 1.44rem !important;
  border-radius: 50% !important;
}
/* override the default style of color picker container */
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
  background: #f8f8f8 !important;
  border: 1px solid !important;
  border-color: #e5e5e5b3 !important;
  border-radius: 0.31rem !important;
}
/* override the default style of color code text string */
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  height: 8px !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: -0.01rem;
}
/* override the existing z-index of DataGrid's individual column Menu panel */
.css-1xif2b4-MuiPopper-root-MuiDataGrid-menu {
  z-index: 5000 !important;
}
/* override the existing z-index of DataGrid Toolbar Menu panel */
.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel {
  z-index: 5000 !important;
}
/* override the existing z-index of DataGrid Toolbar Menu dropdown */
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 5000 !important;
}
.css-74bi4q-MuiDataGrid-overlayWrapper {
  height: 30px !important;
}

/* Tree Checkbox */
.custom-tree-container .p-checkbox-box {
  width: 30px !important;
  height: 23px !important;
  border: 1px solid #010048 !important;
  border-radius: 3px !important;
}

/* Tree Backgroung trasferant */
.p-tree {
  background: transparent !important;
  padding-left: 3.56rem !important;
}

/* Tree Children Node Seleted Label Color*/
.p-treenode-label {
  color: #0100487a !important;
}

/* Tree Children Padding */
.p-treenode-children {
  padding-left: 30px !important;
}

/* Tree Node Checkbox */
/* .p-tree .p-treenode .p-checkbox-box.p-highlight {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
} */

.p-checkbox .p-component .p-highlight {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
  border-radius: 3px !important;
}

/* Tree Node Selected Background Color */
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: transparent !important;
  border: none !important;
}

/* Tree Parent Node Label Color */
.p-tree
  .p-treenode:not(.p-treenode-leaf)
  > .p-treenode-content
  > .p-treenode-label {
  color: #010048 !important;
}

/* Tree Node Backgroung Hover Effect */
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: transparent !important;
}
/* Icon remove  */
.p-tree-toggler {
  display: none !important;
}

/* Tree Checkbox Initailly Icon Color */
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-checkbox
  .p-indeterminate
  .p-checkbox-icon {
  color: #f4f4f4 !important;
}

/* Checkbox Background and Border Color */
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-checkbox
  .p-indeterminate {
  background-color: #5881fe !important;
  border: 1px solid #5881fe !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem !important;
}

.p-treetable .p-treetable-tbody > tr {
  color: #010048 !important;
  border-color: #e0e0e0 !important;
  border-bottom-width: thin !important;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background-color: transparent !important;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left !important;
  padding: 1rem 4.8px !important;
}

.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  /* Custom styles */
  border-radius: 10px;
  border: 1px solid #e9e4e4 !important;
  background: #f4f4f4 !important;
  height: 38px;
  color: rgba(1, 0, 72, 0.5);
  font-size: 15px;
  font-weight: 300;
  padding-left: 10px;
}

.p-multiselect-header {
  background-color: #f4f4f4;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: transparent;
}

.p-component {
  font-family: "Lexend", sans-serif !important;
}

/* .p-multiselect .p-multiselect-label.p-placeholder {
  color: #010048 !important;
  font-size: 0.94rem !important;
  font-weight: 300 !important;
  letter-spacing: -0.225px !important;
} */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: rgba(1, 0, 72, 0.6);
  font-size: 15px !important;
  font-weight: 300 !important;
}
.p-multiselect .p-multiselect-trigger {
  color: #010048 !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.1rem 0.75rem;
  color: #010048;
}
/* single Select dropDown */
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #8998b0 !important;
  font-size: 0.94rem !important;
  font-weight: 300 !important;
  letter-spacing: -0.225px !important;
  font-family: "Lexend", sans-serif !important;
}
.p-inputtext {
  font-family: "Lexend", sans-serif !important;
  padding: 0.375rem 0.75rem;
  font-size: 0.94rem !important;
  /* color: #010048 !important; */
}
.p-secondary-button {
  visibility: visible !important;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  /* font-family: "Lexend", sans-serif !important; */
  font-weight: 300;
  color: #010048 !important;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.05rem 0.75rem;
  font-weight: 300 !important;
  color: #010048 !important;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.17rem 0;
}

/* p-checkbox  */
.p-checkbox .p-checkbox-box {
  border: 1px solid #010048 !important;
  border-radius: 5px !important;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left !important;
  padding: 0.3rem 0.3rem !important;
  height: 3.25rem !important;
}

/* Prime React Tree Table Header and Sort Arrow Set */
.p-treetable .p-column-header-content {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .p-treetable .p-column-header-content {
    display: block !important;
    align-items: center !important;
  }
}
.mobile-screen {
  background: url("../../assets/images/i14.jpg") no-repeat;
  background-size: contain;
  height: 34.38rem;
  min-width: 20.63rem;
}

.sms-preview {
  display: inline-flex;
  justify-content: left;
  align-items: baseline;
  position: relative;
}

.sms-preview {
  width: 11.88rem;
  min-height: 6.25rem;
  margin: 5rem 0 0 4.56rem;
  background-color: #0881fe !important;
  border: 0.13rem solid #0881fe !important;
  color: white;
  border-radius: 1.88rem;
  word-break: break-all;
  padding: 0.63rem;
  font-size: 0.88rem;
  line-height: 1.2 !important;
}

.sms-preview:after {
  content: "";
  position: absolute;
  height: 1.56rem;
  width: 1.38rem;
  bottom: 0.69rem;
  left: 98%;
  top: 80%;
  border-radius: 0 0 3.13rem 0;
  box-shadow: 0 0.75rem 0 0 #0881fe;
  transform: rotate(90deg);
}

/* date range picker */
.rdrCalendarWrapper {
  font-size: 14px !important;
  color: #1e1d1f !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.rdrNextPrevButton {
  display: block !important;
  width: 42px !important;
  height: 42px !important;
  margin: 0 0.833em !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 23px !important;
  background: #eff2f7 !important;
}
.rdrNextButton i {
  margin: 0 0 0 16px !important;
  border-width: 4px 4px 4px 6px !important;
  border-color: transparent transparent transparent rgb(52, 73, 94) !important;
  transform: translate(3px, 0px) !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgba(255, 255, 255) !important;
}
.rdrDayToday .rdrDayNumber span:after {
  content: "" !important;
  position: absolute !important;
  bottom: 4px !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  width: 18px !important;
  height: 2px !important;
  border-radius: 2px !important;
  background: #8217ff !important;
}
.rdrDateDisplayItem {
  display: contents !important;
}
.rdrDateDisplay {
  margin: 0 !important;
}
.rdrMonthsVertical {
  align-items: center !important;
}
@media (max-width: 600px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column !important;
  }
}
/* @media (max-width: 600px) {

.rdrDateRangePickerWrapper{
  max-width: 300px !important;
}
} */

.rdrMonth {
  display: block;
  width: 25em !important;
}

.tippy-box[data-theme~='tomato'] {
  background-color: #5759f9;
  color: white;
  box-shadow: 0 6px 10px -1px #0003, 0 6px 16px #00000024, 0 2px 28px #0000001f !important;
  line-height: 1.35 !important;
  border-radius: 10px;
}
.tippy-box[data-theme~='tomato'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #5759f9 !important;
}
.tippy-box[data-theme~='tomato'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #5759f9 !important;
}
.tippy-box[data-theme~='tomato'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #5759f9 !important;
}
.tippy-box[data-theme~="tomato"] .tippy-content {
  padding: 7%;
  font-weight: 550;
  font-family:Arial, Helvetica, sans-serif !important;
  font-size: 1.15rem !important;
}

/* Stripe Billing Module */
.StripeElement {
  margin: 32px auto;
  padding: 12px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid rgb(52, 73, 94);
  border-radius: 4.96px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

/* .btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
} */

.btn-pay {
  background: #5881FE !important;
  color: #F4F4F4 !important;
  border-radius: 10px;
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  display: block;
}

.btn-pay:hover {
  background: #934CE5 !important;
}

.btn-pay:active {
  background: #934CE5 !important;
}

.btn-pay:disabled {
  cursor: not-allowed;
  background: #5881FE !important;
  color: #F4F4F4 !important;
}

.btn-pay:disabled:hover {
  background: #934CE5 !important;
}

.btn-pay:focus-visible {
  background: #5881FE !important;
  box-shadow: 0px 0px 0px 3px #5881FE !important;
}


.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
.p-datepicker-trigger {
  color: #5881FE;
}


.MuiButtonGroup-root .MuiButtonGroup-firstButton, .css-1de9kl0-MuiButtonGroup-root .MuiButtonGroup-middleButton {
  border-right:none !important;
}

.mainLayout {
  width: calc(100vw - 278px);
  /* height: calc(100vh - 76px); */
  /* width: calc(100vw - 263.2px); */
}
.mobileMainLayout {
 width: 100vw;
}

.p-datepicker-buttonbar {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.invoice-ribbon {
  width:5.313rem;
  height:5.5rem;
  overflow:hidden;
  position:absolute;
  top:-1px;
  /* right:22.5rem; */
}

.ribbon-inner {
  text-align:center;
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
  -ms-transform:rotate(45deg);
  -o-transform:rotate(45deg);
  position:relative;
  padding:7px 0;
  left:-5px;
  top:0.688rem;
  width:7.5rem;
  background-color:#66c591;
  font-size:0.938rem;
  color:#fff;
}

.ribbon-inner:before,.ribbon-inner:after {
  content:'';
  position:absolute;
}

.ribbon-inner:before {
  left:0;
}

.ribbon-inner:after {
  right:0;
}
.MuiButtonGroup-root .MuiButtonGroup-lastButton, .MuiButtonGroup-root .MuiButtonGroup-middleButton {
  padding-left: 0px !important;
  padding-right: 2px !important;
  min-width: 20px !important;
}

.blinking{
  animation:blinkingText 1.0s infinite;
  font-weight: 700;
  font-size: 0.875rem;
}
@keyframes blinkingText{
  0%{     color: #e93232;    }
  10%{     color: #e93232;    }
  50%{    color: #000000; }
  50%{    opacity: 1.0; } 
  90%{    color: #e93232;  }
  100%{   color: #e93232;    }
}