:root {
  --rx-font-ui: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --rx-font-ui-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --rx-rounded-sm: 3px;
  --rx-rounded-md: 6px;
  --rx-rounded-lg: 9px;
  --rx-rounded-xl: 12px;
  --rx-rounded-circle: 99px;
  --rx-level-control: 3;
  --rx-level-toolbar: 4;
  --rx-level-tooltip: 99;
  --rx-level-popup: 100;
  --rx-outset-md: 28px;
  --rx-toolbar-height: 38px;
  --rx-toolbar-padding: 2px;
  --rx-toolbar-button-width: 34px;
  --rx-toolbar-button-height: 34px;
  --rx-toolbar-icon-size: 20px;
  --rx-fg-heading: #070707;
  --rx-fg-text: #2B2B3A;
  --rx-fg-subtle: #63636E;
  --rx-fg-placeholder: #73737C;
  --rx-bg-input: #FFFFFF;
  --rx-bg-input-shaded: #F7F7F8;
  --rx-bg-input-on: #070707;
  --rx-bg-input-off: rgba(7, 7, 7, 0.15);
  --rx-bg-input-disabled: rgba(7, 7, 7, 0.05);
  --rx-bg-aluminum: #f8f8f8;
  --rx-bg-silver: #E6E6E6;
  --rx-bg-platinum: #CDCDCD;
  --rx-bg-base: #FFFFFF;
  --rx-bg-raised: #FFFFFF;
  --rx-bg-overlay: #FFFFFF;
  --rx-bg-toolbar: rgba(255, 255, 255, 0.97);
  --rx-bg-toolbar-raised: rgba(255, 255, 255, 0.97);
  --rx-bg-context: #202020;
  --rx-bg-context-active: #042C6A;
  --rx-bg-source: #202020;
  --rx-border-divider: rgba(7, 7, 7, 0.1);
  --rx-border-input: rgba(7, 7, 7, 0.2);
  --rx-border-focus: #73A9FF;
  --rx-fg-dark-accent: #070707;
  --rx-fg-dark-heading: #070707;
  --rx-fg-dark-text: rgba(7, 7, 7, 0.8);
  --rx-fg-dark-subtle: rgba(7, 7, 7, 0.6);
  --rx-fg-dark-minimal: rgba(7, 7, 7, 0.5);
  --rx-fg-dark-dimmed: rgba(7, 7, 7, 0.4);
  --rx-bg-dark-dimmed: rgba(7, 7, 7, 0.03);
  --rx-bg-dark-minimal: rgba(7, 7, 7, 0.05);
  --rx-bg-dark-subtle: rgba(7, 7, 7, 0.08);
  --rx-bg-dark-medium: rgba(7, 7, 7, 0.15);
  --rx-bg-dark-strong: rgba(7, 7, 7, 0.25);
  --rx-bg-dark-accent: #070707;
  --rx-bg-dark-accent-hover: rgba(7, 7, 7, 0.8);
  --rx-border-dark-dimmed: rgba(7, 7, 7, 0.05);
  --rx-border-dark-minimal: rgba(7, 7, 7, 0.08);
  --rx-border-dark-subtle: rgba(7, 7, 7, 0.1);
  --rx-border-dark-medium: rgba(7, 7, 7, 0.2);
  --rx-border-dark-accent: #070707;
  --rx-fg-light-accent: #FFFFFF;
  --rx-fg-light-heading: #FFFFFF;
  --rx-fg-light-text: rgba(255, 255, 255, 0.8);
  --rx-fg-light-subtle: rgba(255, 255, 255, 0.6);
  --rx-fg-light-minimal: rgba(255, 255, 255, 0.5);
  --rx-fg-light-dimmed: rgba(255, 255, 255, 0.4);
  --rx-bg-light-dimmed: rgba(255, 255, 255, 0.03);
  --rx-bg-light-minimal: rgba(255, 255, 255, 0.05);
  --rx-bg-light-subtle: rgba(255, 255, 255, 0.08);
  --rx-bg-light-medium: rgba(255, 255, 255, 0.15);
  --rx-bg-light-strong: rgba(255, 255, 255, 0.25);
  --rx-bg-light-accent: #FFFFFF;
  --rx-bg-light-accent-hover: rgba(255, 255, 255, 0.8);
  --rx-border-light-dimmed: rgba(255, 255, 255, 0.05);
  --rx-border-light-minimal: rgba(255, 255, 255, 0.08);
  --rx-border-light-subtle: rgba(255, 255, 255, 0.1);
  --rx-border-light-medium: rgba(255, 255, 255, 0.2);
  --rx-border-light-accent: #FFFFFF;
  --rx-fg-black-accent: #070707;
  --rx-fg-black-heading: #070707;
  --rx-fg-black-text: rgba(7, 7, 7, 0.8);
  --rx-fg-black-subtle: rgba(7, 7, 7, 0.6);
  --rx-fg-black-minimal: rgba(7, 7, 7, 0.5);
  --rx-fg-black-dimmed: rgba(7, 7, 7, 0.4);
  --rx-bg-black-dimmed: rgba(7, 7, 7, 0.03);
  --rx-bg-black-minimal: rgba(7, 7, 7, 0.05);
  --rx-bg-black-subtle: rgba(7, 7, 7, 0.08);
  --rx-bg-black-medium: rgba(7, 7, 7, 0.15);
  --rx-bg-black-accent: #070707;
  --rx-bg-black-accent-hover: rgba(7, 7, 7, 0.8);
  --rx-border-black-dimmed: rgba(7, 7, 7, 0.05);
  --rx-border-black-minimal: rgba(7, 7, 7, 0.08);
  --rx-border-black-subtle: rgba(7, 7, 7, 0.1);
  --rx-border-black-medium: rgba(7, 7, 7, 0.2);
  --rx-border-black-accent: #070707;
  --rx-fg-white-accent: #FFFFFF;
  --rx-fg-white-heading: #FFFFFF;
  --rx-fg-white-text: rgba(255, 255, 255, 0.8);
  --rx-fg-white-subtle: rgba(255, 255, 255, 0.6);
  --rx-fg-white-minimal: rgba(255, 255, 255, 0.5);
  --rx-fg-white-dimmed: rgba(255, 255, 255, 0.4);
  --rx-bg-white-dimmed: rgba(255, 255, 255, 0.03);
  --rx-bg-white-minimal: rgba(255, 255, 255, 0.05);
  --rx-bg-white-subtle: rgba(255, 255, 255, 0.08);
  --rx-bg-white-medium: rgba(255, 255, 255, 0.15);
  --rx-bg-white-accent: #FFFFFF;
  --rx-bg-white-accent-hover: rgba(255, 255, 255, 0.8);
  --rx-border-white-dimmed: rgba(255, 255, 255, 0.05);
  --rx-border-white-minimal: rgba(255, 255, 255, 0.08);
  --rx-border-white-subtle: rgba(255, 255, 255, 0.1);
  --rx-border-white-medium: rgba(255, 255, 255, 0.2);
  --rx-border-white-accent: #FFFFFF;
  --rx-fg-primary-accent: #0063FF;
  --rx-fg-primary-strong: #0247B5;
  --rx-fg-primary-text: #043583;
  --rx-bg-primary-minimal: #F2F7FF;
  --rx-bg-primary-subtle: #E5EFFF;
  --rx-bg-primary-medium: #C9DEFF;
  --rx-bg-primary-accent: #0063FF;
  --rx-bg-primary-accent-hover: #0247B5;
  --rx-bg-primary-static: #0063FF;
  --rx-border-primary-minimal: #C9DEFF;
  --rx-border-primary-subtle: #99C1FF;
  --rx-border-primary-medium: #99C1FF;
  --rx-border-primary-accent: #0063FF;
  --rx-border-primary-static: #0063FF;
  --rx-fg-negative-accent: #D70015;
  --rx-fg-negative-strong: #990211;
  --rx-fg-negative-text: #6F040E;
  --rx-bg-negative-minimal: #FFF0F1;
  --rx-bg-negative-subtle: #FFE1E5;
  --rx-bg-negative-medium: #FFC1C8;
  --rx-bg-negative-accent: #D70015;
  --rx-bg-negative-accent-hover: #D70015;
  --rx-bg-negative-static: #D70015;
  --rx-border-negative-minimal: #FFC1C8;
  --rx-border-negative-subtle: #FF8994;
  --rx-border-negative-medium: #FF8994;
  --rx-border-negative-acccent: #D70015;
  --rx-border-negative-static: #D70015;
  --rx-link-color: #0063FF;
  --rx-link-hover-color: #070707;
  --rx-link-dark-color: #070707;
  --rx-link-dark-hover-color: rgba(7, 7, 7, 0.6);
  --rx-link-dark-subtle-color: rgba(7, 7, 7, 0.6);
  --rx-link-dark-subtle-hover-color: #070707;
  --rx-shadow-modal: 0 0 1px rgba(7, 7, 7, 0.25), 0 5px 10px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05), 0 20px 40px rgba(7, 7, 7, 0.05), 0 40px 80px rgba(7, 7, 7, 0.05);
  --rx-shadow-dropdown: 0 0 1px rgba(7, 7, 7, 0.25), 0 1px 2px rgba(7, 7, 7, 0.05), 0 3px 6px rgba(7, 7, 7, 0.05), 0 2px 4px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05);
  --rx-shadow-toolbar-raised: 0 1px 3px rgba(7, 7, 7, 0.15), 0 1px 2px rgba(7, 7, 7, 0.06);
  --rx-shadow-reorder: 0 15px 30px rgba(7, 7, 7, 0.3);
  --rx-shadow-inner: inset 0 1px 1px rgba(7, 7, 7, 0.15);
  --rx-shadow-sm: 0 1px 3px rgba(7, 7, 7, 0.15);
  --rx-shadow-md: 0 1px 2px rgba(7, 7, 7, 0.05), 0 3px 6px rgba(7, 7, 7, 0.05), 0 2px 4px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05);
  --rx-shadow-lg: 0 5px 10px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05), 0 20px 40px rgba(7, 7, 7, 0.05), 0 40px 80px rgba(7, 7, 7, 0.05);
  --rx-shadow-sm-border: 0 0 1px rgba(7, 7, 7, 0.25), 0 1px 3px rgba(7, 7, 7, 0.15);
  --rx-shadow-md-border: 0 0 1px rgba(7, 7, 7, 0.25), 0 1px 2px rgba(7, 7, 7, 0.05), 0 3px 6px rgba(7, 7, 7, 0.05), 0 2px 4px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05);
  --rx-shadow-lg-border: 0 0 1px rgba(7, 7, 7, 0.25), 0 5px 10px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05), 0 20px 40px rgba(7, 7, 7, 0.05), 0 40px 80px rgba(7, 7, 7, 0.05);
}

[rx-data-theme=dark] {
  --rx-fg-heading: #D8D8DB;
  --rx-fg-text: #C8C8CC;
  --rx-fg-subtle: #73737C;
  --rx-fg-placeholder: #73737C;
  --rx-bg-input: #1B1B1B;
  --rx-bg-input-shaded: #1B1B1B;
  --rx-bg-input-on: #73A9FF;
  --rx-bg-input-off: rgba(255, 255, 255, 0.2);
  --rx-bg-input-disabled: rgba(255, 255, 255, 0.05);
  --rx-bg-aluminum: #202020;
  --rx-bg-silver: #2A2A2A;
  --rx-bg-platinum: #2F2F2F;
  --rx-bg-base: #1B1B1B;
  --rx-bg-raised: #202020;
  --rx-bg-overlay: #202020;
  --rx-bg-toolbar: rgba(32, 32, 32, 0.97);
  --rx-bg-toolbar-raised: rgba(32, 32, 32, 0.97);
  --rx-bg-context-active: #C9DEFF;
  --rx-border-divider: rgba(255, 255, 255, 0.1);
  --rx-border-input: rgba(255, 255, 255, 0.2);
  --rx-border-focus: #73A9FF;
  --rx-fg-dark-accent: #FFFFFF;
  --rx-fg-dark-heading: rgba(255, 255, 255, 0.9);
  --rx-fg-dark-text: rgba(255, 255, 255, 0.8);
  --rx-fg-dark-subtle: rgba(255, 255, 255, 0.6);
  --rx-fg-dark-minimal: rgba(255, 255, 255, 0.5);
  --rx-fg-dark-dimmed: rgba(255, 255, 255, 0.4);
  --rx-bg-dark-dimmed: rgba(255, 255, 255, 0.03);
  --rx-bg-dark-minimal: rgba(255, 255, 255, 0.05);
  --rx-bg-dark-subtle: rgba(255, 255, 255, 0.08);
  --rx-bg-dark-medium: rgba(255, 255, 255, 0.15);
  --rx-bg-dark-strong: rgba(255, 255, 255, 0.25);
  --rx-bg-dark-accent: #F7F7F8;
  --rx-bg-dark-accent-hover: rgba(255, 255, 255, 0.8);
  --rx-border-dark-dimmed: rgba(255, 255, 255, 0.05);
  --rx-border-dark-minimal: rgba(255, 255, 255, 0.08);
  --rx-border-dark-subtle: rgba(255, 255, 255, 0.1);
  --rx-border-dark-medium: rgba(255, 255, 255, 0.2);
  --rx-border-dark-accent: #F7F7F8;
  --rx-fg-light-accent: #070707;
  --rx-fg-light-heading: rgba(7, 7, 7, 0.9);
  --rx-fg-light-text: rgba(7, 7, 7, 0.8);
  --rx-fg-light-subtle: rgba(7, 7, 7, 0.6);
  --rx-fg-light-minimal: rgba(7, 7, 7, 0.5);
  --rx-fg-light-dimmed: rgba(7, 7, 7, 0.4);
  --rx-bg-light-dimmed: rgba(7, 7, 7, 0.03);
  --rx-bg-light-minimal: rgba(7, 7, 7, 0.05);
  --rx-bg-light-subtle: rgba(7, 7, 7, 0.08);
  --rx-bg-light-medium: rgba(7, 7, 7, 0.15);
  --rx-bg-light-strong: rgba(7, 7, 7, 0.25);
  --rx-bg-light-accent: #070707;
  --rx-bg-light-accent-hover: rgba(7, 7, 7, 0.8);
  --rx-border-light-dimmed: rgba(7, 7, 7, 0.05);
  --rx-border-light-minimal: rgba(7, 7, 7, 0.08);
  --rx-border-light-subtle: rgba(7, 7, 7, 0.1);
  --rx-border-light-medium: rgba(7, 7, 7, 0.2);
  --rx-border-light-accent: #070707;
  --rx-fg-primary-accent: #73A9FF;
  --rx-fg-primary-strong: #99C1FF;
  --rx-fg-primary-text: #C9DEFF;
  --rx-bg-primary-minimal: #070D18;
  --rx-bg-primary-subtle: #061939;
  --rx-bg-primary-medium: #042C6A;
  --rx-bg-primary-accent: #73A9FF;
  --rx-bg-primary-accent-hover: #99C1FF;
  --rx-bg-primary-static: #0063FF;
  --rx-border-primary-minimal: #042C6A;
  --rx-border-primary-subtle: #043583;
  --rx-border-primary-medium: #043583;
  --rx-border-primary-accent: #73A9FF;
  --rx-border-primary-static: #0063FF;
  --rx-fg-negative-accent: #FF5D6C;
  --rx-fg-negative-strong: #FF8994;
  --rx-fg-negative-text: #FFC1C8;
  --rx-bg-negative-minimal: #160708;
  --rx-bg-negative-subtle: #31060A;
  --rx-bg-negative-medium: #5A040D;
  --rx-bg-negative-accent: #FF5D6C;
  --rx-bg-negative-accent-hover: #FF8994;
  --rx-bg-negative-static: #D70015;
  --rx-border-negative-minimal: #5A040D;
  --rx-border-negative-subtle: #6F040E;
  --rx-border-negative-medium: #6F040E;
  --rx-border-negative-accent: #FF5D6C;
  --rx-border-negative-static: #D70015;
  --rx-link-color: #73A9FF;
  --rx-link-hover-color: #FFFFFF;
  --rx-link-dark-color: #FFFFFF;
  --rx-link-dark-hover-color: rgba(255, 255, 255, 0.6);
  --rx-link-dark-subtle-color: rgba(255, 255, 255, 0.6);
  --rx-link-dark-subtle-hover-color: #FFFFFF;
  --rx-shadow-modal: 0 0 1px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05), 0 20px 40px rgba(7, 7, 7, 0.05), 0 40px 80px rgba(7, 7, 7, 0.05);
  --rx-shadow-dropdown: 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(7, 7, 7, 0.05), 0 3px 6px rgba(7, 7, 7, 0.05), 0 2px 4px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05);
  --rx-shadow-sm-border: 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 3px rgba(7, 7, 7, 0.15);
  --rx-shadow-md-border: 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(7, 7, 7, 0.05), 0 3px 6px rgba(7, 7, 7, 0.05), 0 2px 4px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05);
  --rx-shadow-lg-border: 0 0 1px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(7, 7, 7, 0.05), 0 10px 20px rgba(7, 7, 7, 0.05), 0 20px 40px rgba(7, 7, 7, 0.05), 0 40px 80px rgba(7, 7, 7, 0.05);
}

@keyframes slideUp {
  to {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@keyframes slideDown {
  from {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.rx-container {
  position: relative;
  box-sizing: border-box;
}

.rx-container.rx-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow-x: auto;
}

.rx-main-container {
  border-radius: var(--rx-rounded-sm);
  background-color: var(--rx-bg-base);
  border: 1px solid var(--rx-border-dark-minimal);
}

.rx-stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.rx-editor-frame {
  width: 100%;
  height: 0;
  border: none;
  background-color: var(--rx-bg-base);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.rx-editor-container {
  box-sizing: border-box;
}

.rx-editor-disabled {
  opacity: 0.4;
}
.rx-editor-disabled img {
  filter: grayscale(1);
}

.rx-editor-overlay {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  z-index: 1;
  background: transparent;
  top: 0;
  left: 0;
}

.rx-toolbox-container {
  background: var(--rx-bg-toolbar);
  z-index: var(--rx-level-toolbar);
}

.rx-editor-breakline table + *, .rx-editor-breakline blockquote + *, .rx-editor-breakline figure + *, .rx-editor-breakline > div + *,
.rx-editor-breakline p + *, .rx-editor-breakline ul + *, .rx-editor-breakline ol + *, .rx-editor-breakline pre + *, .rx-editor-breakline dl + *, .rx-editor-breakline address + * {
  margin-top: 0 !important;
}
.rx-editor-breakline h1 + h2,
.rx-editor-breakline h2 + h3,
.rx-editor-breakline h3 + h4,
.rx-editor-breakline h4 + h5,
.rx-editor-breakline h5 + h6 {
  margin-top: 0 !important;
}

.rx-editor *, .rx-editor *::before, .rx-editor *::after {
  box-sizing: inherit;
}

.rx-editor {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
.rx-editor *[dir=rtl] {
  text-align: right;
}
.rx-editor *[dir=ltr] {
  text-align: left;
}
.rx-editor, .rx-editor [data-rx-type], .rx-editor a, .rx-editor p, .rx-editor figcaption, .rx-editor td, .rx-editor th {
  outline: none;
}
.rx-editor [data-rx-tag=tbr], .rx-editor [data-rx-tag=br] {
  margin-top: 0;
}
.rx-editor [data-rx-tag=tbr]:empty, .rx-editor [data-rx-tag=br]:empty, .rx-editor [data-rx-type=text]:empty, .rx-editor [data-rx-type=todoitem] div:empty {
  min-width: 1em;
  min-height: 1.5em;
}
.rx-editor::after {
  content: "";
  clear: both;
  display: table;
}
.rx-editor [data-rx-type=embed],
.rx-editor [data-rx-type=image] {
  position: relative;
}
.rx-editor [data-rx-type=embed] img,
.rx-editor [data-rx-type=image] img {
  vertical-align: middle;
}
.rx-editor figure > img, .rx-editor figure > a img, .rx-editor figure > iframe, .rx-editor figure > pre, .rx-editor figure > div {
  vertical-align: middle;
}
.rx-editor [data-rx-focusable].rx-block-focus {
  outline: 2px solid var(--rx-border-focus) !important;
}
.rx-editor .rx-block-control-focus {
  outline: 1px solid var(--rx-border-focus) !important;
  outline-offset: 1px;
}
.rx-editor [data-rx-type=wrapper]:empty {
  outline: 1px dashed var(--rx-border-dark-medium);
  outline-offset: 1px;
}
.rx-editor [data-rx-type=heading] a {
  text-decoration: underline;
}
.rx-editor .rx-layout-grid {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.rx-editor [data-rx-type=column] {
  outline: 1px dashed var(--rx-border-dark-medium);
}
.rx-editor.rx-editor-email [data-rx-type=column] {
  outline: none;
}
.rx-editor.rx-editor-email [data-rx-type=column]:hover, .rx-editor.rx-editor-email [data-rx-type=column]:has(p:empty) {
  outline: 1px dashed var(--rx-border-dark-medium);
}
.rx-editor .rx-nowrap {
  white-space: nowrap;
}
.rx-editor [data-rx-type=embed] {
  position: relative;
}
.rx-editor [data-rx-type=embed]:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  z-index: 1;
}
.rx-editor [data-rx-type=embed] iframe {
  display: inline-block;
}
.rx-editor [data-rx-type=embed] figcaption {
  position: relative;
  top: 0;
  z-index: 2;
}
.rx-editor [data-rx-type=embed].rx-block-focus:before {
  display: none;
}
.rx-editor .rx-embed-placeholder {
  text-align: center;
  border: 1px dashed var(--rx-border-dark-medium);
  border-radius: var(--rx-rounded-md);
  padding: 40px 20px;
  font-size: 14px;
  color: var(--rx-fg-placeholder);
}
.rx-editor [data-rx-type=todo] {
  --rx-todo-size: 20px;
  --rx-todo-mark-color: var(--rx-fg-light-accent);
  --rx-todo-border-color: var(--rx-border-input);
  --rx-todo-bg: var(--rx-bg-input);
  --rx-todo-checked-border-color: var(--rx-border-dark-accent);
  --rx-todo-checked-bg: var(--rx-bg-dark-accent);
  margin-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.rx-editor [data-rx-type=todoitem] {
  display: flex;
  gap: 6px;
  cursor: text;
}
.rx-editor [data-rx-type=todoitem] input {
  position: relative;
  top: 1px;
  appearance: none;
  display: inline-block;
  padding: 0;
  box-shadow: none;
  width: var(--rx-todo-size);
  height: var(--rx-todo-size);
  cursor: pointer;
}
.rx-editor [data-rx-type=todoitem] input:before {
  content: "";
  display: block;
  width: var(--rx-todo-size);
  height: var(--rx-todo-size);
  border: 1px solid var(--rx-todo-border-color);
  border-radius: 6px;
  background: var(--rx-todo-bg);
}
.rx-editor [data-rx-type=todoitem] input:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--rx-todo-size) / 3.5);
  height: calc(var(--rx-todo-size) / 1.8);
  background-color: transparent;
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
}
.rx-editor [data-rx-type=todoitem] input:checked:before {
  border-color: var(--rx-todo-checked-border-color);
  background-color: var(--rx-todo-checked-bg);
  box-shadow: var(--rx-todo-checked-shadow);
}
.rx-editor [data-rx-type=todoitem] input:checked:after {
  transform: translate(-50%, -85%) scale(1) rotate(45deg);
  box-shadow: 2px 2px 0px 0px var(--rx-todo-mark-color);
}
.rx-editor [data-rx-type=todoitem] div {
  outline: none;
  line-height: 1.4;
}
.rx-editor [data-rx-type=mergetag] {
  font-family: var(--rx-font-ui-mono);
  font-size: 85%;
  line-height: 1;
  display: inline-block;
  cursor: pointer;
  padding: 4px 6px;
  color: var(--rx-fg-primary-text);
  background: var(--rx-bg-primary-subtle);
  border: 1px solid var(--rx-border-primary-subtle);
  border-radius: 4px;
}
.rx-editor [data-rx-type=mergetag]:hover, .rx-editor [data-rx-type=mergetag].rx-block-focus {
  background: var(--rx-bg-primary-medium);
}
.rx-editor .rx-block-placeholder {
  position: relative;
}
.rx-editor .rx-block-placeholder:before {
  position: absolute;
}
.rx-editor .rx-block-placeholder:before,
.rx-editor [data-placeholder]:empty:before {
  content: attr(data-placeholder);
  display: inline-block;
  color: var(--rx-fg-placeholder);
  font-weight: normal;
  font-style: italic;
  font-size: inherit;
  cursor: text;
  max-height: 20px;
}
.rx-editor [data-placeholder]:empty:focus:before {
  content: "";
}

.rx-editor.rx-placeholder:before {
  position: absolute;
  z-index: 0;
  content: attr(placeholder);
  color: var(--rx-fg-placeholder);
  font-weight: normal;
  cursor: text;
}

.rx-in-blur .rx-editor [data-rx-focusable].rx-block-focus {
  outline-color: var(--rx-border-dark-medium) !important;
}

.rx-draggable-placeholder {
  height: 20px;
  min-width: 80px;
  outline: 3px dashed gold !important;
  background: rgba(255, 215, 0, 0.2) !important;
  margin-bottom: 20px;
}

.rx-toolbar-container {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.rx-toolbar-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.rx-toolbar-container:empty {
  display: none;
}

.rx-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.rx-sticky-on {
  border-bottom: 1px solid var(--rx-border-dark-minimal);
}

.rx-raised {
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  background: var(--rx-bg-toolbar-raised);
  box-shadow: var(--rx-shadow-toolbar-raised);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rx-toolbox-external.rx-raised {
  margin: 0;
}

.rx-raised.rx-sticky-on {
  border-bottom: none;
}

.rx-toolbar-buttons,
.rx-extrabar-buttons {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 1px;
}

.rx-toolbar,
.rx-extrabar {
  padding: var(--rx-toolbar-padding);
  height: var(--rx-toolbar-height);
}

.rx-toolbar {
  order: 1;
  flex: 1;
}

.rx-extrabar {
  order: 2;
}

.rx-toolbar .rx-button,
.rx-extrabar .rx-button {
  justify-content: center;
  width: var(--rx-toolbar-button-width);
  height: var(--rx-toolbar-button-height);
}
.rx-toolbar .rx-button-icon,
.rx-extrabar .rx-button-icon {
  width: var(--rx-toolbar-button-width);
  min-width: var(--rx-toolbar-button-width);
  height: var(--rx-toolbar-button-height);
}
.rx-toolbar .rx-button-icon svg,
.rx-extrabar .rx-button-icon svg {
  width: var(--rx-toolbar-icon-size);
  height: var(--rx-toolbar-icon-size);
}
.rx-toolbar .rx-button-title,
.rx-extrabar .rx-button-title {
  display: none;
}

.rx-pathbar {
  font-family: var(--rx-font-ui);
  margin: 0;
  padding: 0 8px;
  position: relative;
  overflow: hidden;
  background: var(--rx-bg-toolbar);
  box-sizing: border-box;
  border: none;
  display: flex;
  gap: 12px;
  border-bottom: 1px solid var(--rx-border-dark-dimmed);
}
.rx-pathbar:empty {
  display: none;
}

.rx-pathbar.disable .rx-pathbar-item {
  opacity: 0.5;
}

.rx-pathbar-item {
  position: relative;
  font-size: 12px;
  padding: 6px 0;
  margin: 0;
  line-height: 16px;
  color: var(--rx-fg-dark-minimal);
  cursor: pointer;
}

.rx-pathbar-item:hover {
  color: var(--rx-fg-dark-accent);
  text-decoration: underline;
}

.rx-pathbar-item.active,
.rx-pathbar-item.active:hover {
  color: var(--rx-fg-dark-accent);
  text-decoration: none;
  cursor: text;
}

.rx-pathbar-item:after {
  position: absolute;
  content: "-";
  width: 8px;
  padding-left: 3px;
  font-size: 12px;
  line-height: 16px;
  color: var(--rx-fg-dark-minimal);
}

.rx-pathbar-item:last-child:after {
  display: none;
}

.rx-statusbar {
  font-family: var(--rx-font-ui-mono);
  margin: 0;
  padding: 8px 10px;
  position: relative;
  overflow: hidden;
  background: var(--rx-bg-base);
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.rx-statusbar:empty {
  display: none;
}

.rx-statusbar.disable .rx-statusbar-item {
  opacity: 0.5;
}

.rx-statusbar-item {
  font-size: 12px;
  padding: 0;
  margin: 0;
  line-height: 16px;
  color: var(--rx-fg-text);
}

.rx-statusbar-item:after {
  content: "";
  display: inline-block;
  position: relative;
  top: 2px;
  width: 1px;
  height: 12px;
  margin-left: 10px;
  background: var(--rx-border-divider);
}

.rx-statusbar-item:last-child:after {
  display: none;
}

.rx-statusbar-item a {
  color: var(--rx-link-dark-color);
  text-decoration: underline;
}

.rx-statusbar-item a:hover {
  color: var(--rx-link-dark-hover-color);
  text-decoration: underline;
}

.rx-fullscreen .rx-statusbar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.rx-control {
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--rx-font-text);
  z-index: var(--rx-level-control);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 28px;
  line-height: 0;
}

.rx-control-buttons {
  display: flex;
  gap: 1;
  flex-direction: column;
  flex-wrap: wrap;
}

.rx-control .rx-button {
  justify-content: center;
  background: var(--rx-bg-raised);
}
.rx-control .rx-button-title {
  display: none;
}
.rx-control .rx-button-icon svg {
  width: 14px;
  height: 14px;
}

.rx-context {
  font-family: var(--rx-font-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--rx-level-toolbar);
  border-radius: var(--rx-rounded-sm);
  padding: 2px;
  margin: 0;
  line-height: 1;
  background: var(--rx-bg-context);
  box-shadow: var(--rx-shadow-sm-border);
  max-width: 380px;
}

.rx-context-line {
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  padding: 6px;
  padding-bottom: 8px;
  border-radius: var(--rx-rounded-sm);
  display: flex;
  align-items: center;
  vertical-align: baseline;
}
.rx-context-line, .rx-context-line a {
  color: var(--rx-fg-white-text);
}
.rx-context-line a:hover {
  color: var(--rx-fg-white-subtle);
}

.rx-context-buttons {
  display: flex;
  align-items: center;
  gap: 1px;
  flex-wrap: wrap;
}

.rx-context .rx-button {
  justify-content: center;
}
.rx-context .rx-button:hover, .rx-context .rx-button.toggled {
  background: var(--rx-bg-white-medium);
}
.rx-context .rx-button:hover .rx-button-icon svg, .rx-context .rx-button.toggled .rx-button-icon svg {
  fill: var(--rx-fg-white-text);
}
.rx-context .rx-button.active {
  background: var(--rx-bg-context-active);
}
.rx-context .rx-button.disable .rx-button-icon svg {
  fill: var(--rx-fg-white-dimmed);
}
.rx-context .rx-button-icon svg {
  fill: var(--rx-fg-white-text);
  width: 18px;
  height: 18px;
}
.rx-context .rx-button-title {
  display: none;
}

.rx-button {
  font-family: var(--rx-font-text);
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0px;
  line-height: 1;
  cursor: pointer;
  gap: 6px;
  border-radius: var(--rx-rounded-sm);
  width: 28px;
  height: 28px;
  transition: none;
}
.rx-button:hover {
  text-decoration: none;
}
.rx-button:hover {
  background: var(--rx-bg-platinum);
}
.rx-button:hover .rx-button-icon svg {
  fill: var(--rx-fg-dark-accent);
}
.rx-button.toggled {
  background: var(--rx-bg-silver);
}
.rx-button.toggled .rx-button-icon svg {
  fill: var(--rx-fg-dark-accent);
}
.rx-button.active {
  background: var(--rx-bg-primary-medium);
}
.rx-button.disable {
  cursor: default;
  background-color: transparent !important;
}
.rx-button.disable .rx-button-icon svg {
  fill: var(--rx-fg-dark-dimmed);
}

.rx-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: var(--rx-rounded-sm);
  width: 28px;
  min-width: 28px;
  height: 28px;
}
.rx-button-icon:empty {
  display: none;
}
.rx-button-icon svg {
  fill: var(--rx-fg-dark-text);
  width: 16px;
  height: 16px;
}

.rx-button-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  color: var(--rx-fg-dark-text);
}

.rx-tooltip {
  position: absolute;
  z-index: 99;
  margin: 0;
  padding: 4px 7px;
  border-radius: var(--rx-rounded-sm);
  line-height: 1;
  font-family: var(--rx-font-ui-mono);
  font-size: 12px;
  font-weight: 500;
  color: var(--rx-fg-white-text);
  background: var(--rx-bg-black-accent);
  pointer-events: none;
}

.rx-source-container {
  display: none;
}

.rx-source,
.rx-source:hover,
.rx-source:focus {
  text-align: left;
  box-sizing: border-box;
  font-family: var(--rx-font-ui-mono);
  width: 100%;
  display: block;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
  background-color: var(--rx-bg-source);
  color: var(--rx-fg-white-text);
  font-size: 14px;
  line-height: 1.7;
  outline: none;
  padding: 10px 18px 20px 18px;
  min-height: 60px;
  resize: vertical;
}

.rx-drag-active {
  outline: 3px dashed gold !important;
  outline-offset: 0 !important;
  position: relative;
  max-height: 40px;
  overflow: hidden;
  padding: 0;
}
.rx-drag-active:before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background: rgba(255, 215, 0, 0.4);
  position: absolute;
  z-index: 1;
}

.rx-dragging {
  opacity: 0.95;
  padding: 8px;
  background: var(--rx-bg-body);
  box-shadow: var(--rx-shadow-reorder);
}
.rx-dragging img {
  max-width: 100%;
}

.rx-dropdown,
.rx-colorpicker {
  position: absolute;
  font-family: var(--rx-font-ui);
  border-radius: var(--rx-rounded-sm);
  background: var(--rx-bg-overlay);
  box-shadow: var(--rx-shadow-dropdown);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  line-height: 1.4;
  z-index: var(--rx-level-popup);
  margin-bottom: 16px;
  overflow-x: auto;
  min-width: 200px;
  padding: 4px;
}

.rx-dropdown[data-rx-pos=top] {
  box-shadow: none;
}

.rx-dropdown-swatches {
  display: flex;
  flex-wrap: nowrap;
}

.rx-dropdown-swatches-wrap {
  flex-wrap: wrap;
}

.rx-dropdown-swatches-colors {
  display: flex;
  flex-direction: column;
}

.rx-dropdown-swatches-colors-row {
  flex-direction: row;
}

.rx-dropdown-swatch {
  cursor: pointer;
  position: relative;
  font-size: 0;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
}
.rx-dropdown-swatch.active {
  border: 2px solid var(--rx-border-dark-accent);
  box-shadow: 0 0 0 2px var(--rx-border-light-accent);
  z-index: 2;
}

.rx-dropdown-swatch-size-large {
  width: 32px;
  height: 32px;
}

.rx-dropdown-color-contrast {
  border-color: var(--rx-border-dark-subtle);
}

.rx-dropdown-tabs {
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
  display: flex;
  gap: 2px;
  border-radius: var(--rx-rounded-sm);
  background: var(--rx-bg-dark-minimal);
  padding: 2px;
  margin-bottom: 4px;
}

.rx-dropdown-tab {
  flex-grow: 1;
  border-radius: var(--rx-rounded-sm);
  padding: 6px 8px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: var(--rx-fg-dark-subtle);
}

.rx-dropdown-tab:hover,
.rx-dropdown-tab.active {
  text-decoration: none;
  background: var(--rx-bg-dark-subtle);
  color: var(--rx-fg-dark-text);
}

.rx-dropdown-tab.active {
  cursor: text;
  text-decoration: none;
  background: var(--rx-bg-light-accent);
  color: var(--rx-fg-dark-text);
  box-shadow: var(--shadow-sm-border);
}

.rx-dropdown-item {
  font-size: 14px;
  line-height: 1.4;
  padding: 6px;
  display: flex;
  color: var(--rx-fg-dark-text);
  border-bottom: 1px solid var(--rx-border-dark-dimmed);
}
.rx-dropdown-item .rx-button-title {
  display: none;
}

.rx-dropdown-item:last-child {
  border-bottom-width: 0;
}

.rx-dropdown-item-hotkey {
  margin-left: auto;
  font-weight: normal;
  display: flex;
  gap: 1px;
  align-items: center;
  color: var(--rx-fg-dark-minimal);
  font-family: var(--rx-font-ui);
  font-size: 12px;
  line-height: 16px;
}
.rx-dropdown-item-hotkey span {
  padding: 0 2px;
  height: 16px;
}

.rx-dropdown .rx-button {
  position: relative;
  padding: 6px;
  height: auto;
  width: 100%;
}
.rx-dropdown .rx-button-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.rx-dropdown .rx-button.rx-button-swatch {
  width: 20px;
  height: 20px;
  border-radius: 0;
}
.rx-dropdown .rx-button.rx-button-swatch .rx-button-icon,
.rx-dropdown .rx-button.rx-button-swatch .rx-button-title {
  display: none;
}
.rx-dropdown .rx-button-title {
  color: var(--rx-fg-dark-text);
}
.rx-dropdown .rx-button-danger .rx-button-title {
  color: var(--rx-fg-negative-accent);
}
.rx-dropdown .rx-button-danger svg {
  fill: var(--rx-fg-negative-accent);
}

.rx-dropdown-type-grid .rx-dropdown-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  box-sizing: border-box;
}
.rx-dropdown-type-grid .rx-button {
  box-sizing: border-box;
  border-radius: 0;
  margin-bottom: -1px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--rx-fg-dark-text);
}
.rx-dropdown-type-grid .rx-button:after {
  display: none;
}
.rx-dropdown-type-grid .rx-button-title:empty {
  display: none;
}
.rx-dropdown-type-grid .rx-dropdown-item svg {
  width: 16px;
  height: 16px;
}

.rx-dropdown-stack {
  padding: 16px;
  padding-top: 0;
}

.rx-dropdown-stack-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 1;
  border-bottom: 1px solid var(--rx-border-dark-dimmed);
}

.rx-dropdown-stack-item:last-child {
  border-bottom-color: transparent;
}

.rx-dropdown-stack-item:hover {
  background-color: var(--rx-bg-dark-minimal);
}

.rx-dropdown-stack-title {
  text-decoration: none;
  flex: 1;
  padding: 7px 8px;
  font-size: 14px;
  color: var(--rx-fg-dark-text);
}

.rx-dropdown-stack-aside {
  font-family: var(--rx-font-ui-mono);
  font-size: 11px;
  color: var(--rx-fg-dark-subtle);
  margin-left: auto;
  padding-left: 4px;
}

.rx-modal {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 16px;
  min-width: 200px;
  font-family: var(--rx-font-ui);
  border-radius: var(--rx-rounded-md);
  background-color: var(--rx-bg-overlay);
  box-shadow: var(--rx-shadow-modal);
  z-index: var(--rx-level-popup);
}

.rx-modal-body .rx-form {
  padding-bottom: 0;
}

.rx-modal-header {
  font-size: 16px;
  font-weight: bold;
  color: var(--rx-bg-dark-accent);
  padding: 16px;
  position: relative;
}
.rx-modal-header:empty {
  display: none;
}

.rx-modal-footer {
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 16px;
  padding-bottom: 20px;
  display: flex;
  gap: 4px;
}
.rx-modal-footer:empty {
  display: none;
}

.rx-modal-close {
  position: absolute !important;
  right: 16px;
  top: 14px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.rx-modal-close::before, .rx-modal-close::after {
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: var(--rx-bg-dark-accent);
}
.rx-modal-close::before {
  height: 1px;
  width: 75%;
}
.rx-modal-close::after {
  height: 75%;
  width: 1px;
}
.rx-modal-close:hover {
  opacity: 1;
}

.rx-form {
  padding-bottom: 16px;
}

.rx-form-item {
  padding: 16px 16px 0 16px;
}
.rx-form-item:empty {
  display: none;
}

.rx-form-box {
  padding: 16px;
}

.rx-form-flex {
  display: flex;
  gap: 4px;
}

.rx-form-image img, .rx-form-image svg {
  max-width: 100%;
}

.rx-form-title,
.rx-form-section-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--rx-bg-dark-accent);
  padding: 16px;
  padding-bottom: 0;
  position: relative;
}
.rx-form-title:empty,
.rx-form-section-title:empty {
  display: none;
}

.rx-form-item + .rx-form-section-title {
  margin-top: 8px;
}

.rx-form-footer {
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  gap: 4px;
}
.rx-form-footer:empty {
  display: none;
}

.rx-form-label {
  box-sizing: border-box;
  font-family: var(--rx-font-ui);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  display: block;
  padding: 0;
  margin: 0;
  padding-bottom: 6px;
  color: var(--rx-fg-dark-text);
}

.rx-form-hint {
  font-size: 13px;
  font-weight: normal;
  color: var(--rx-fg-dark-subtle);
  margin-left: 2px;
}

.rx-form-container-flex {
  display: flex;
  gap: 8px;
}
.rx-form-container-flex .rx-form-item {
  padding-left: 0;
  padding-right: 0;
}
.rx-form-container-flex .rx-form-item:first-child {
  padding-left: 16px;
}
.rx-form-container-flex .rx-form-item:last-child {
  padding-right: 16px;
}
.rx-form-container-flex .rx-form-input,
.rx-form-container-flex .rx-form-select {
  flex: auto;
}
.rx-form-container-flex .rx-form-button {
  margin-left: 4px;
}

.rx-form-button-push-right {
  margin-left: auto;
}

.rx-form-color-container {
  position: relative;
}

.rx-form-color-container .rx-form-input {
  padding-left: 40px;
}

.rx-form-color-toggle {
  position: absolute;
  top: 4px;
  left: 6px;
  height: 28px;
  width: 28px;
  border-radius: 8px;
  box-shadow: var(--rx-shadow-inner);
  cursor: pointer;
  background: var(--rx-bg-white-accent);
}

.rx-form-button {
  box-sizing: border-box;
  font-family: var(--rx-font-ui);
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border-radius: 8px;
  padding: 8px 20px;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.25;
  height: auto;
  background: transparent;
  color: var(--rx-fg-dark-accent);
  border: 1px solid var(--rx-border-dark-medium);
}
.rx-form-button:hover {
  background: var(--rx-bg-dark-subtle);
}

.rx-form-button-primary {
  background: var(--rx-bg-dark-accent);
  color: var(--rx-fg-light-accent);
  border-color: transparent;
}
.rx-form-button-primary:hover {
  color: var(--rx-fg-light-accent);
  background: var(--rx-bg-dark-accent-hover);
}

.rx-form-button-danger {
  border: 1px solid var(--rx-border-negative-medium);
  color: var(--rx-fg-negative-accent);
}
.rx-form-button-danger:hover {
  color: var(--rx-fg-negative-accent);
  background: var(--rx-bg-negative-subtle);
}

.rx-form-button-fullwidth {
  display: block;
  width: 100%;
}

.rx-form-input,
.rx-form-select,
.rx-form-textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-weight: normal;
  padding: 8px;
  font-family: var(--rx-font-ui);
  font-size: 15px;
  outline: none;
  border-radius: 4px;
  box-shadow: none;
  line-height: 1.3;
  color: var(--rx-fg-dark-text);
  background: var(--rx-bg-input-shaded);
  border: 1px solid var(--rx-border-input);
}
.rx-form-input:focus,
.rx-form-select:focus,
.rx-form-textarea:focus {
  border-color: var(--rx-border-focus);
  box-shadow: 0 0 0 1px var(--rx-border-focus);
}

.rx-form-textarea {
  resize: vertical;
  line-height: 1.5;
}

.rx-form-select {
  font-size: 15px;
  cursor: pointer;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg height="6" viewBox="0 0 10 6" width="10" xmlns="http://www.w3.org/2000/svg"><path fill="rgb(0,0,0)" opacity=".6" d="m6.6168815 3-4.44908109-4.09883609c-.22373388-.20615371-.22373388-.54039492 0-.74654863s.58647818-.20615371.81021206 0l4.85418712 4.47211041c.22373388.20615371.22373388.54039491 0 .74654862l-4.85418712 4.47211041c-.22373388.20615371-.58647818.20615371-.81021206 0s-.22373388-.54039492 0-.74654863z" fill-rule="evenodd" transform="matrix(0 1 -1 0 8 -2)"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.65em center;
  padding-right: 28px;
  min-height: 35px;
}

[rx-data-theme=dark] .rx-form-select {
  background-image: url('data:image/svg+xml;utf8,<svg height="6" viewBox="0 0 10 6" width="10" xmlns="http://www.w3.org/2000/svg"><path fill="rgb(255,255,255)" opacity="0.6" d="m6.6168815 3-4.44908109-4.09883609c-.22373388-.20615371-.22373388-.54039492 0-.74654863s.58647818-.20615371.81021206 0l4.85418712 4.47211041c.22373388.20615371.22373388.54039491 0 .74654862l-4.85418712 4.47211041c-.22373388.20615371-.58647818.20615371-.81021206 0s-.22373388-.54039492 0-.74654863z" fill-rule="evenodd" transform="matrix(0 1 -1 0 8 -2)"/></svg>');
}

.rx-form-checkbox {
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  top: -0.05em;
  font-size: 16px;
  margin-top: 0 !important;
  margin-right: 4px !important;
  margin-left: 0 !important;
}

.rx-form-checkbox-item {
  color: var(--rx-fg-dark-text);
  font-family: var(--rx-font-ui);
  font-size: 15px;
  line-height: 1.4;
}

.rx-form-segment {
  display: flex;
  flex-wrap: wrap;
  background: var(--rx-bg-dark-minimal);
  border-radius: var(--rx-rounded-md);
  padding: 2px;
  gap: 4px;
}

.rx-form-segment-item {
  border: 1px solid transparent;
  font-size: 14px;
  padding: 6px 8px;
  margin: 0;
  cursor: pointer;
  text-align: center;
  border-radius: var(--rx-rounded-sm);
  min-width: 32px;
  color: var(--rx-fg-dark-subtle);
}
.rx-form-segment-item:hover {
  background: var(--rx-bg-light-accent);
  color: var(--rx-fg-dark-accent);
}
.rx-form-segment-item.active {
  cursor: default;
  background: var(--rx-bg-light-accent);
  box-shadow: var(--rx-shadow-sm-border);
  color: var(--rx-fg-dark-accent);
}

.rx-form-upload-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 10px;
  border: 1px dashed var(--rx-border-dark-medium);
  background: var(--rx-bg-dark-dimmed);
  border-radius: var(--rx-rounded-md);
  min-width: 160px;
  height: 160px;
  cursor: pointer;
  position: relative;
}
.rx-form-upload-box input[type=file] {
  font-size: 13px;
}
.rx-form-upload-box img {
  position: relative;
  z-index: 2;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.rx-form-upload-box:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.rx-form-upload-box.rx-form-upload-hover {
  border-color: var(--rx-border-dark-medium);
  background-color: var(--rx-bg-dark-subtle);
}
.rx-form-upload-box.rx-form-upload-error {
  border-color: var(--rx-border-negative-medium);
  background-color: var(--rx-bg-negative-minimal);
}

.rx-upload-remove {
  position: absolute;
  z-index: 3;
  top: -6px;
  right: -10px;
  background-color: var(--rx-bg-black-accent);
  color: var(--rx-fg-white-accent);
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
}
.rx-upload-remove:after {
  content: "×";
}
.rx-upload-remove:hover {
  background-color: var(--rx-bg-black-accent-hover);
}

.rx-form-upload-cover-off img {
  object-fit: initial;
  height: auto;
  width: auto;
}

.rx-form-upload-placeholder {
  font-family: var(--rx-font-ui);
  color: var(--rx-fg-dark-minimal);
  font-size: 12px;
  line-height: 1.35;
  padding: 0 8px;
  text-align: center;
}
.rx-form-upload-placeholder svg {
  fill: var(--rx-fg-dark-minimal);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.rx-editor-progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11000;
  width: 100%;
  background-color: var(--rx-bg-dark-subtle);
}
.rx-editor-progress span {
  animation: progress-bar-stripes 2s linear infinite;
  content: "";
  display: block;
  min-height: 8px;
  width: 100%;
  height: 100%;
  background-color: var(--rx-bg-primary-accent);
  background-image: -webkit-linear-gradient(45deg, var(--rx-bg-light-strong) 25%, transparent 25%, transparent 50%, var(--rx-bg-light-strong) 50%, var(--rx-bg-light-strong) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, var(--rx-bg-light-strong) 25%, transparent 25%, transparent 50%, var(--rx-bg-light-strong) 50%, var(--rx-bg-light-strong) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.rx-panel {
  position: absolute;
  min-width: 240px;
  max-height: 300px;
  margin: 0;
  padding: 0;
  z-index: 99;
  font-family: var(--rx-font-ui);
  font-size: 14px;
  overflow: auto;
  border-radius: 4px;
  padding: 4px;
  background: var(--rx-bg-overlay);
  box-shadow: var(--rx-shadow-dropdown);
}

.rx-panel-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.rx-panel-title {
  font-family: var(--rx-font-ui);
  font-size: 13px;
  font-weight: 500;
  color: var(--rx-fg-dark-subtle);
}

.rx-panel-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.rx-panel-item {
  box-sizing: border-box;
  padding: 4px;
  border-bottom: 1px solid transparent;
}

.rx-panel-item {
  display: flex;
  align-items: center;
  vertical-align: middle;
  gap: 6px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  text-decoration: none;
  color: var(--rx-fg-dark-accent);
}

.rx-panel-item:hover {
  color: var(--rx-fg-dark-accent);
  background: var(--rx-bg-dark-subtle);
}

.rx-panel-item.active {
  color: var(--rx-fg-dark-accent);
  background: var(--rx-bg-primary-medium);
}

.rx-panel-item-icon {
  min-width: 16px;
}

.rx-panel-item-icon svg {
  width: 16px;
  height: 16px;
  fill: var(--rx-fg-dark-accent);
}

.rx-panel-stack .rx-panel-box {
  flex-direction: column;
}

.rx-ai-main {
  padding: 12px;
  border-radius: 8px;
  background: var(--rx-bg-raised);
  border: 1px solid var(--rx-border-dark-subtle);
}

.rx-ai-footer {
  margin-top: 16px !important;
  display: flex;
  gap: 8px;
}

.rx-ai-buttons {
  margin-left: auto !important;
  display: flex;
  gap: 8px;
}

.rx-ai-preview-label,
.rx-ai-label {
  box-sizing: border-box;
  font-family: var(--rx-font-ui);
  font-size: 13px;
  line-height: 1.4;
  font-weight: 500;
  display: block;
  padding: 0;
  margin: 0;
  padding-bottom: 6px;
  color: var(--rx-fg-dark-subtle);
}

.rx-ai-select,
.rx-ai-size {
  width: auto;
  max-width: 320px;
}

.rx-ai-progress {
  margin-bottom: 12px !important;
}
.rx-ai-progress:empty {
  display: none;
}
.rx-ai-progress svg {
  fill: var(--rx-fg-dark-accent);
}

.rx-ai-preview {
  font-family: var(--rx-font-ui);
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  font-size: 16px;
  line-height: 1.618;
  color: var(--rx-fg-dark-text);
  margin-bottom: 12px !important;
}
.rx-ai-preview:empty {
  display: none;
}

@media only screen and (max-width: 767px) {
  .rx-ai-footer,
  .rx-ai-buttons {
    flex-direction: column;
    width: 100%;
  }
  .rx-ai-select,
  .rx-ai-size {
    max-width: 100%;
  }
  .rx-ai-button {
    justify-content: center;
  }
}
.rx-voice-label {
  display: none;
}

[rx-modal-name=image] .rx-modal-body {
  padding: 16px;
  padding-top: 0;
}

.rx-modal-image-section-or {
  margin: 16px 0;
  font-family: var(--rx-font-ui);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 11px;
  color: var(--rx-fg-dark-subtle);
}

.rx-modal-images-box {
  margin-top: 12px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.rx-modal-images-box img {
  vertical-align: top;
  max-width: 100px;
  max-height: 66px;
  height: 100%;
  width: min-content;
  cursor: pointer;
}
.rx-modal-images-box img:hover {
  outline: 3px solid var(--rx-border-focus);
}

.rx-form-item-edit-image-box {
  padding: 20px;
  padding-bottom: 0;
}

.rx-form-item-image {
  margin-right: 8px;
}
.rx-form-item-image img {
  max-height: 160px;
  width: auto;
}

[rx-modal-name=emoji] .rx-modal-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rx-emoji-title {
  font-family: var(--rx-font-ui);
  font-size: 13px;
  font-weight: 500;
  color: var(--rx-fg-dark-subtle);
}

.rx-emoji-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.rx-emoji-item {
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
}

.rx-emoji-item:hover {
  background: var(--rx-bg-dark-subtle);
}

.rx-panel-emoji {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rx-panel-emoji .rx-panel-item {
  font-size: 24px;
}

[rx-modal-name=snippets] .rx-modal-body,
[rx-modal-name=templates] .rx-modal-body {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.rx-snippet-container {
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 20px;
}

.rx-snippet-name {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--rx-font-ui);
  color: var(--rx-fg-dark-text);
  margin-top: 8px;
  padding-right: 10px;
  text-align: left;
  max-width: 100px;
}

.rx-snippet-box {
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: var(--shadow-md-border);
  cursor: pointer;
  border: 1px solid transparent;
  overflow: hidden;
}
.rx-snippet-box:hover {
  border: 1px solid var(--rx-border-primary-accent);
}

.rx-snippet-image,
.rx-template-image {
  min-height: 80px;
  max-height: 80px;
  box-sizing: border-box;
}
.rx-snippet-image img,
.rx-template-image img {
  max-width: 160px;
}

.rx-template-image {
  min-height: 130px;
  max-height: initial;
}
.rx-template-image img {
  max-width: 100px;
}

.rx-snippet-preview,
.rx-template-preview {
  box-sizing: border-box;
  overflow: hidden;
  transform-origin: top left;
  transform: scale(0.25);
  margin-right: -480px;
  margin-bottom: -240px;
  padding: 40px;
  width: 640px;
  height: 320px;
  position: relative;
}
.rx-snippet-preview:before,
.rx-template-preview:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  z-index: 1;
}
.rx-snippet-preview:hover,
.rx-template-preview:hover {
  border-color: var(--rx-border-primary-accent);
}
.rx-snippet-preview fieldset, .rx-snippet-preview iframe, .rx-snippet-preview img,
.rx-template-preview fieldset,
.rx-template-preview iframe,
.rx-template-preview img {
  border: 0;
}
.rx-snippet-preview embed, .rx-snippet-preview object, .rx-snippet-preview audio, .rx-snippet-preview img, .rx-snippet-preview video,
.rx-template-preview embed,
.rx-template-preview object,
.rx-template-preview audio,
.rx-template-preview img,
.rx-template-preview video {
  max-width: 100%;
  height: auto;
}
.rx-snippet-preview h1:empty,
.rx-snippet-preview h2:empty,
.rx-snippet-preview h3:empty,
.rx-snippet-preview h4:empty,
.rx-snippet-preview h5:empty,
.rx-snippet-preview h6:empty,
.rx-snippet-preview p:empty,
.rx-snippet-preview div:empty,
.rx-template-preview h1:empty,
.rx-template-preview h2:empty,
.rx-template-preview h3:empty,
.rx-template-preview h4:empty,
.rx-template-preview h5:empty,
.rx-template-preview h6:empty,
.rx-template-preview p:empty,
.rx-template-preview div:empty {
  background-color: var(--rx-border-primary-subtle);
  min-height: 1.5em;
}

.rx-template-preview {
  transform: scale(0.25);
  margin-right: -300px;
  margin-bottom: -390px;
  padding: 40px;
  width: 400px;
  height: 520px;
}

.rx-empty h1:empty,
.rx-empty h2:empty,
.rx-empty h3:empty,
.rx-empty h4:empty,
.rx-empty h5:empty,
.rx-empty h6:empty,
.rx-empty p:empty,
.rx-empty li:empty,
.rx-empty dt:empty,
.rx-empty dd:empty,
.rx-empty address:empty,
.rx-empty figcaption:empty,
.rx-empty blockquote:empty {
  min-height: 1.5em;
}
.rx-empty pre:empty {
  min-height: 3.5em;
}
.rx-empty strong:empty, .rx-empty b:empty, .rx-empty em:empty, .rx-empty i:empty, .rx-empty span:empty, .rx-empty sup:empty, .rx-empty sub:empty, .rx-empty u:empty, .rx-empty ins:empty, .rx-empty code:empty, .rx-empty del:empty, .rx-empty cite:empty {
  display: inline-block;
  min-width: 1px;
  min-height: 1em;
}
.rx-empty th:empty:after, .rx-empty td:empty:after {
  content: "​";
}
.rx-empty code:after, .rx-empty kbd:after, .rx-empty mark:after {
  content: "​";
}
.rx-empty pre code:after {
  display: none;
}
.rx-empty code + code {
  margin-left: 2px;
}
.rx-empty table {
  empty-cells: show;
}
.rx-empty embed, .rx-empty img, .rx-empty object {
  max-width: 100%;
  height: auto;
}

.rx-content {
  text-align: left;
  --rx-font-text: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --rx-font-heading: inherit;
  --rx-font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --rx-body-color: var(--rx-bg-body);
  --rx-heading-color: var(--rx-fg-heading);
  --rx-text-color: var(--rx-fg-text);
  --rx-table-border: var(--rx-border-dark-minimal);
  --rx-line-color: var(--rx-border-dark-minimal);
  --rx-pre-color: var(--rx-fg-text);
  --rx-pre-bg: var(--rx-bg-dark-minimal);
  --rx-var-color: var(--rx-fg-text);
  --rx-kbd-color: var(--rx-fg-text);
  --rx-kbd-border: var(--rx-border-dark-subtle);
  --rx-kbd-bg: var(--rx-bg-light-accent);
  --rx-code-color: var(--rx-fg-text);
  --rx-code-bg: var(--rx-bg-dark-medium);
  --rx-abbr-border: var(--rx-border-dark-accent);
  --rx-quote-border-color: var(--rx-border-dark-accent);
  background: var(--rx-body-color);
  font-family: var(--rx-font-text);
  color: var(--rx-text-color);
  font-size: 16px;
  line-height: 1.5;
}
.rx-content[dir=rtl] {
  text-align: right;
}
.rx-content * {
  margin: 0;
}
.rx-content p, .rx-content li, .rx-content dt, .rx-content dd, .rx-content address {
  font-size: 16px;
  line-height: 1.5;
}
.rx-content h1 + *, .rx-content h2 + *, .rx-content h3 + *, .rx-content h4 + *, .rx-content h5 + *, .rx-content h6 + * {
  margin-top: 12px;
}
.rx-content p + h2, .rx-content p + h3, .rx-content p + h4, .rx-content p + h5, .rx-content p + h6, .rx-content ul + h2, .rx-content ul + h3, .rx-content ul + h4, .rx-content ul + h5, .rx-content ul + h6, .rx-content ol + h2, .rx-content ol + h3, .rx-content ol + h4, .rx-content ol + h5, .rx-content ol + h6, .rx-content pre + h2, .rx-content pre + h3, .rx-content pre + h4, .rx-content pre + h5, .rx-content pre + h6, .rx-content dl + h2, .rx-content dl + h3, .rx-content dl + h4, .rx-content dl + h5, .rx-content dl + h6, .rx-content address + h2, .rx-content address + h3, .rx-content address + h4, .rx-content address + h5, .rx-content address + h6 {
  margin-top: 24px;
}
.rx-content p + *, .rx-content ul + *, .rx-content ol + *, .rx-content pre + *, .rx-content dl + *, .rx-content address + * {
  margin-top: 24px;
}
.rx-content table + *,
.rx-content blockquote + *,
.rx-content figure + *, .rx-content > div + * {
  margin-top: 24px;
}
.rx-content h1 + h2,
.rx-content h2 + h3,
.rx-content h3 + h4,
.rx-content h4 + h5,
.rx-content h5 + h6 {
  margin-top: 12px;
}
.rx-content a,
.rx-content a:hover,
.rx-content a:focus,
.rx-content a:visited {
  color: var(--rx-link-color);
}
.rx-content h1,
.rx-content h2,
.rx-content h3,
.rx-content h4,
.rx-content h5,
.rx-content h6 {
  font-family: var(--rx-font-heading);
  font-weight: bold;
  font-style: normal;
  color: var(--rx-heading-color);
  text-rendering: optimizeLegibility;
  letter-spacing: 0;
}
.rx-content h1 a,
.rx-content h2 a,
.rx-content h3 a,
.rx-content h4 a,
.rx-content h5 a,
.rx-content h6 a {
  text-decoration: underline;
}
.rx-content h1 {
  font-weight: 800;
  letter-spacing: -0.02em;
}
.rx-content h2 {
  font-weight: 700;
  letter-spacing: -0.02em;
}
.rx-content h3,
.rx-content h4,
.rx-content h5,
.rx-content h6 {
  font-weight: 600;
  letter-spacing: -0.01em;
}
.rx-content h1 {
  font-size: 36px;
  line-height: 1.25;
}
.rx-content h2 {
  font-size: 24px;
  line-height: 1.25;
}
.rx-content h3 {
  font-size: 20px;
  line-height: 1.25;
}
.rx-content h4 {
  font-size: 16px;
  line-height: 1.25;
}
.rx-content h5 {
  font-size: 16px;
  line-height: 1.25;
}
.rx-content h6 {
  font-size: 16px;
  line-height: 1.25;
}
.rx-content dfn,
.rx-content abbr {
  font-size: 95%;
}
.rx-content cite,
.rx-content small,
.rx-content var,
.rx-content kbd,
.rx-content code {
  font-size: 85%;
}
.rx-content sup,
.rx-content sub {
  font-size: 65%;
}
.rx-content var,
.rx-content code,
.rx-content kbd {
  display: inline-block;
  font-family: var(--rx-font-mono);
  font-style: normal;
  line-height: 1;
  vertical-align: baseline;
}
.rx-content kbd,
.rx-content code {
  border-radius: 4px;
}
.rx-content kbd {
  color: var(--rx-kbd-color);
  background: var(--rx-kbd-bg);
  border: 1px solid var(--rx-kbd-border);
  padding: 0.2em 0.4em;
}
.rx-content var {
  color: var(--rx-var-color);
}
.rx-content code {
  position: relative;
  top: -1px;
  padding: 0.2em 0.4em 0.2em;
  color: var(--rx-code-color);
  background: var(--rx-code-bg);
  border: 1px solid var(--rx-code-border);
}
.rx-content mark {
  background-color: yellow;
  color: black;
}
.rx-content b,
.rx-content strong {
  font-weight: bold;
}
.rx-content dfn,
.rx-content abbr[title] {
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px dotted var(--rx-abbr-border);
  cursor: help;
}
.rx-content cite {
  color: var(--rx-cite-color, var(--rx-text-color));
  font-style: italic;
}
.rx-content sub,
.rx-content sup {
  line-height: 1;
  margin-left: 2px;
}
.rx-content sub {
  vertical-align: sub;
}
.rx-content sup {
  vertical-align: super;
}
.rx-content ul,
.rx-content ol {
  padding-left: 0;
  margin-left: 20px;
}
.rx-content li li {
  font-size: 1em;
}
.rx-content dt {
  font-weight: bold;
}
.rx-content dd + dt {
  margin-top: 0.25em;
}
.rx-content pre {
  overflow: auto;
  white-space: pre;
  font-family: var(--rx-font-mono);
  font-size: 14px;
  line-height: 1.6;
  padding: 1em;
  border-radius: 3px;
  border: 1px solid var(--rx-pre-border, transparent);
  background: var(--rx-pre-bg, transparent);
  color: var(--rx-pre-color, var(--rx-text-color));
}
.rx-content pre code {
  position: initial;
  color: inherit;
  padding: 0;
  border: 0;
  font-size: 100%;
  display: block;
  line-height: inherit;
  background: none;
}
.rx-content blockquote,
.rx-content figure:has(blockquote) {
  padding: 0;
  background: var(--rx-quote-bg, transparent);
  padding-left: 24px;
  border-left: var(--rx-quote-border-width, 3px) solid var(--rx-quote-border-color);
}
.rx-content blockquote p,
.rx-content figure:has(blockquote) p {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: var(--rx-quote-color, var(--rx-text-color));
}
.rx-content blockquote p + p,
.rx-content figure:has(blockquote) p + p {
  margin-top: 0.5em;
}
.rx-content figure:has(blockquote) blockquote {
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  border-radius: 0;
}
.rx-content blockquote cite,
.rx-content figcaption cite {
  font-style: italic;
  font-weight: normal;
  color: var(--rx-quote-caption-color, var(--rx-text-color));
  font-size: 14px;
  line-height: 1.3;
}
.rx-content caption {
  text-align: left;
  font-style: normal;
}
.rx-content table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  empty-cells: show;
  font-size: 15px;
  line-height: 1.5;
  color: var(--rx-table-color, var(--rx-text-color));
}
.rx-content td,
.rx-content th {
  text-align: left;
  vertical-align: top;
  padding: 0.75em;
  border: 1px solid var(--rx-table-border);
}
.rx-content th {
  font-weight: normal;
}
.rx-content thead th {
  font-weight: bold;
}
.rx-content address {
  font-style: italic;
}
.rx-content figcaption {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.3;
  background: var(--rx-caption-bg, transparent);
  color: var(--rx-caption-color, var(--rx-text-color));
}
.rx-content figure > img, .rx-content figure > img a, .rx-content figure > iframe, .rx-content figure > pre, .rx-content figure > div {
  vertical-align: middle;
}
.rx-content .embed-responsive:has(iframe) {
  position: relative;
  padding: 0;
  padding-bottom: 56.25%;
  height: 0;
}
.rx-content .embed-responsive:has(iframe) iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rx-content div.embed-responsive {
  margin: 0;
}
.rx-content hr {
  --rx-line-size: 1px;
  --rx-line-width: 100%;
  --rx-line-style: solid;
  position: relative;
  line-height: 1;
  background: none;
  border: none !important;
  text-align: left;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin: 0 !important;
  opacity: 1;
  width: auto;
}
.rx-content hr:after {
  content: "";
  position: absolute;
  top: 50%;
  width: var(--rx-line-width);
  max-width: var(--rx-line-width);
  margin-top: -calc(var(--rx-line-size)/2);
  border-top-width: var(--rx-line-size);
  border-top-style: var(--rx-line-style);
  border-top-color: var(--rx-line-color);
}
.rx-content .wrap-center {
  text-align: center;
}
.rx-content .wrap-center img {
  margin-left: auto;
  margin-right: auto;
}
.rx-content .wrap-center figcaption {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.rx-content .float-left {
  float: left;
  margin-right: 1em;
  margin-bottom: 1em;
  max-width: 200px;
}
.rx-content .float-right {
  float: right;
  margin-left: 1em;
  margin-bottom: 1em;
  max-width: 200px;
}
.rx-content .outset-right {
  position: relative;
  width: calc(100% + var(--rx-outset-md));
  max-width: calc(100% + var(--rx-outset-md));
  transform: translateX(var(--rx-outset-md)) translate3d(0, 0, 0);
  left: calc(var(--rx-outset-md) * -1);
}
.rx-content .outset-left {
  width: calc(100% + var(--rx-outset-md));
  max-width: calc(100% + var(--rx-outset-md));
  transform: translateX(calc(var(--rx-outset-md) * -1)) translate3d(0, 0, 0);
}
.rx-content .outset-both {
  position: relative;
  width: calc(100% + var(--rx-outset-md) + var(--rx-outset-md));
  max-width: calc(100% + var(--rx-outset-md) + var(--rx-outset-md));
  transform: translateX(-50%) translate3d(0, 0, 0);
  left: 50%;
}